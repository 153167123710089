import React, { FunctionComponent, useState } from 'react';
import { isEqual } from 'lodash';

import { Typography } from '@mui/material';

import { enqueueSnackbar } from 'notistack';

import { Client } from '../../types/models';
import * as API from '../../services/API';

import PopupDialog from '../shared/PopupDialog';

import { IPortfolioReportStateData } from './index';

interface ICloneReportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  reportData?: IPortfolioReportStateData;
  refreshReports: () => void;
  clientId?: Client['id'];
}

const CloneReportDialog: FunctionComponent<ICloneReportDialogProps> = (
  props: ICloneReportDialogProps
) => {
  const [loading, isLoading] = useState<boolean>(false);

  const cloneReport = async (): Promise<void> => {
    isLoading(true);
    try {
      await API.create(
        `/clients/${props.clientId}/portfolio_reports/${props.reportData?.id}/report_clone`,
        {}
      );
      enqueueSnackbar('Report cloned successfully', {
        variant: 'success',
      });
      isLoading(false);
      props.onClose();
      props.refreshReports();
    } catch (e: any) {
      enqueueSnackbar(e?.body?.message ? e.body.message : 'Failed to clone report', {
        variant: 'error',
      });
      isLoading(false);
      props.onClose();
    }
  };

  const onClose = () => {
    props.onClose();
  };

  return (
    <PopupDialog
      open={props.isOpen}
      close={onClose}
      title="Clone Report"
      isLoading={loading}
      primaryButtons={[
        {
          id: 'clone-report-dialog',
          label: 'Clone',
          onClick: cloneReport,
          disabled: isEqual(props.reportData?.Name, ''),
        },
      ]}
    >
      <Typography>
        Are you sure you want to clone "
        <strong>{props.reportData?.Name}</strong>"?
      </Typography>
    </PopupDialog>
  );
};

export default CloneReportDialog;
